import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Box, DetailsLink, Grid, Image, Typography } from '.';

const ImageWrapper = styled(Image)`
  box-shadow: ${({ type }) => (type === 'large' ? '-25px -25px 0px 0px var(--secondary-color)' : '-15px -15px 0px 0px var(--secondary-color)')};

  margin-left: ${({ type }) => (type === 'large' ? 'auto' : 0)};
`;

export default function Blog({ blog, type = 'small', isLink = true }) {
  return (
    <Grid
      gridTemplateColumns={{
        _: '1fr',
        sm: `5fr 2fr`,
      }}
    >
      <Box display="flex" alignItems="center" mb={{ _: 2, sm: 0 }}>
        {isLink ? (
          <DetailsLink to={`/blogs/${blog.slug}`} variant="underline" textVariant={type === 'large' ? 'heading2' : 'heading4'} showStartArrow={false}>
            {blog.title}
          </DetailsLink>
        ) : (
          <Typography color="#fff" variant={type === 'large' ? 'heading2' : 'heading4'}>
            {blog.title}
          </Typography>
        )}
      </Box>
      <Box textAlign="right">
        <Link to={`/blogs/${blog.slug}`}>
          <ImageWrapper type={type} src={blog.featuredImage.node.mediaItemUrl} alt={blog.title} />
        </Link>
      </Box>
    </Grid>
  );
}
