import { graphql } from 'gatsby';
import React from 'react';
import { Article, Box, Container, Layout, RawHTML, Section } from '../components';
import Blog from '../components/Blog';

export default function BlogTemplate({ data }) {
  const pageData = data?.wpgraphql?.blog ?? {};

  return (
    <Layout title={pageData.title} description={pageData.blog_fields.blogMetaDescription || pageData.content} image={pageData.featuredImage.node.mediaItemUrl}>
      <Container mt={{ _: 8, md: 10 }}>
        <Box maxWidth={{ _: '100%', md: '900px' }} position="relative" pt={3} pl={{ _: 2, md: 0 }} mx="auto">
          <Blog type="large" blog={pageData} isLink={false} />
        </Box>
        <Section mt={{ _: '60px', md: '80px' }}>
          <Article>
            <RawHTML html={pageData.content} />
          </Article>
        </Section>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      blog(id: $id) {
        id
        title
        slug
        content
        featuredImage {
          node {
            altText
            mediaItemUrl
          }
        }
        blog_fields {
          blogMetaDescription
        }
      }
    }
  }
`;
